import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Projects from "../components/projects/Projects"
import ProHeader from "../components/Projects_Header"
import Welcome from "../components/Welcome"

const MainPage = () => {
  return(
    <>
      <div className=" flex-col w-full p-0 flex items-center relative">
        <div className="pt-8 w-full px-8 md:w-5/6 xl:min-h-screen m-0 flex flex-col justify-between lg:pt-14 xl:pt-24 gap-0 lg:gap-4 xl:gap-0">
          <Navbar />
          <Welcome />
          <Footer />
        </div>
        <div className="w-5/6 text-center md:text-start md:pb-8 md:pt-12 xl:pt-24 xl:pb-16 px-8 hidden md:flex flex-col gap-8 xl:gap-16 font-normal md:text-lg xl:text-2xl">
          <div className="w-full">Für dich als Kind oder Jugendlicher gibt es die Möglichkeit an Aktivitäten teilzunehmen.<br />Jede/r ist herzlich willkommen – deine Ideen werden gehört!</div>
          <div className="w-full">Für Sie als Eltern/Erziehungsberechtigte stehen wir als Informationsdrehscheibe zur Verfügung,<br /> wir bieten Elternbildungsangebote und Möglichkeiten sich in Gruppen auszutauschen.</div>
        </div>
        <div className="w-5/6 py-8 xl:py-24">
          <ProHeader />
          <Projects />
        </div>
      <div className="w-full bg-yellow flex items-center gap-4 p-4 md:py-4 flex-col">
        <div />
        <div className="text-sm lg:text-lg xl:text-2xl font-semibold">© ARGE 2024</div>
        <div className="text-sm lg:text-lg xl:text-2xl">Datenschutz - Cookies - Impressum</div>
        <div />
      </div>
      </div>
    </>
  )
}

export default MainPage