import Project from "./SingleProject"
import { useState } from "react"

const Projects = () => {

  const [projects, setProjects] = useState([
    {
      key: 0,
      img: "eltern_helfen_eltern.png",
      title: "Eltern stärken Eltern",
      text: "Psychoedukative Selbsthilfegruppe mit dem Ziel Schulverweigerer erneut in die Schule einzuführen.",
      link: 'https://lebenswirksamkeit-eg.at'
    },
    {
      key: 1,
      img: "familie_als_bonus.png",
      title: "Familie als Bonus",
      text: "FABO-Familie als Bonus - Erwachsenenbildung - Eltern-Sein, wie geht das? Für werdende Eltern bis Eltern von 4 jährigen Kindern.",
      link: 'https://www.familiealsbonus.at/'
    },
    {
      key: 2,
      img: "jeki_bus.png",
      title: "JEKI",
      text: "Ein kreativer Treffpunkt mit Bastel- und Outdoormaterialien für Kinder, inklusive Beratung zu regionalen Angeboten.",
      link: '/jeki'
    },
    {
      key: 3,
      img: "move_your_body.png",
      title: "Move Your Body",
      text: "Erlebe Freude an der Bewegung und entdecke spannende neue Sportarten. Gemeinsam bleiben wir aktiv und fit!",
      link: '/move_your_body'
    },
    {
      key: 4,
      img: "hot_pot.png",
      title: "Hot-Pot.KOMM",
      text: "Küche und Garten als offener Kommunikationsraum für Gemeinsames Kochen, Genießen und Erleben von Garten und Natur.",
      link: '/hot_pot'
    }
  ])

  return(
    <div className="w-full pt-0 md:pt-8 lg:pt-12 xl:pt-16 flex flex-col gap-8 lg:gap-16 xl:gap-20 xl:px-8">
      {projects.map((project, index) => {
        return <Project project={project} isLeft={index%2==0}/>
      })}
    </div>
  )
}

export default Projects