import Info from "../components/project_details/Info";
import ProjectInfo from "../components/project_details/ProjectHeader";
import PageContainer from "./PageContainer";
import { useEffect } from "react";
import Basketball from "../assets/web/basketball_web.svg"
import Herz from "../assets/web/karotte_web.svg"

const MoveYourBody = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const project = {
    headline:'Move your Body',
    info: 'Move your Body ist ein kostenloses Sportangebot für ALLE Kinder und Jugendlichen aus dem Bezirk Hartberg-Fürstenfeld. Unser Angebot bezieht sich auf bekannte Sportarten, sowie auch darauf neue Sportarten vorzustellen und auszuprobieren.',
    image: 'move_your_body.png'
  }
  return(
    <PageContainer>
      <div className="flex flex-col gap-8 md:gap-12 lg:gap-20 xl:gap-32">
        <ProjectInfo project={project}/>
        <div className="flex flex-col w-full gap-16">
          <div className="text-center md:text-start text-2xl lg:text-3xl xl:text-5xl font-semibold">Was bieten wir?</div>
            <div className="flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-x-8 gap-y-12 md:gap-y-32">
              <Info text={<span>Gegenseitige <span className="font-semibold">Motivation</span>, <span className="font-semibold">Wissensaneingung</span> und <span className="font-semibold">Erweiterung</span> der Hobbies im Sportbereich.</span>} icon={Basketball}/>
              <Info text={<span>Aufklärung über gute <span className="font-semibold">Ernährung</span>, verschiedene Nahrungsmittel und deren <span className="font-semibold">Gesundheitswert</span>.</span>} icon={Herz}/>
              <div className="flex w-full flex-col gap-4 md:gap-12 pr-12">
                <div className="text-lg lg:text-2xl font-semibold ">Wo und wann findest du uns?</div>
                <div className="text-sm lg:text-lg xl:text-2xl">Standort : Gartengasse 6, 8230 Hartberg<br />Bei Schulbetrieb - Dienstags von 15 Uhr bis 17 Uhr</div>
              </div>
              <div className="flex w-full flex-col gap-4 md:gap-12 pr-12">
                <div className="text-lg lg:text-2xl font-semibold">Anmeldung</div>
                <div className="text-sm lg:text-lg xl:text-2xl">Kerstin Kitting (0676 88015 7013) oder<br/>Sebastian Kühberger (0676/ 898 678 786)</div>
              </div>
            </div>
              <div className="flex w-full flex-col gap-4 md:pr-12 md:pt-16 pb-4">
                <div className="text-sm lg:text-lg xl:text-2xl w-full md:w-2/3">Im Sommer werden an verschiedenen Terminen und Standorten Outdoorspiele und Sporteinheiten stattfinden.</div>
                <div className="text-sm lg:text-lg xl:text-2xl w-full md:w-2/3">Wir freuen uns darauf mit Dir in die nächste Sporteinheit zu starten! Euer Move your Body Team, Sebastian und Kerstin</div>
              </div>
        </div>
      </div>
      <div className="h-40"></div>
    </PageContainer>
  )
}

export default MoveYourBody;