import Info from "../components/project_details/Info";
import ProjectInfo from "../components/project_details/ProjectHeader";
import PageContainer from "./PageContainer";
import { useEffect } from "react";
import Kochen from "../assets/web/kochen_web.svg"
import Miteinander from "../assets/web/miteinander_web.svg"
import Sozial from "../assets/web/sozial&selbstbewusst_web.svg"
import Apfel from "../assets/web/apfel_web.svg"



const HotPot = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const project = {
    headline:'Hot Pot',
    info: 'Alle sind in diesem Projekt willkommen, die einen freudvollen Zugang zu gesunder Ernährung bekommen wollen, die bunt, lecker und unkompliziert ist. Dabei können auch neue soziale Kontakte und Freundschaften geknüpft werden.',
    image: 'hot_pot.png'
  }
  return(
    <PageContainer>
      <div className="flex flex-col gap-8 md:gap-12 lg:gap-20 xl:gap-32">
        <ProjectInfo project={project}/>
        <div className="flex flex-col w-full gap-8 md:gap-16">
            <div className="text-center md:text-start text-2xl lg:text-3xl xl:text-5xl font-semibold">Worum geht's?</div>
            <div className="flex flex-col md:grid md:grid-cols-2 md:grid-rows-2 gap-x-8 gap-y-12 md:gap-y-24">
              <Info text={<span>Gemeinsames <span className="font-semibold">Kochen, Genießen</span> und <span className="font-semibold">Erleben</span> von Garten und Natur.</span>} icon={Kochen}/>
              <Info text={<span><span className="font-semibold">Austausch</span>, gemeinsame <span className="font-semibold">positive Erlebnisse</span>.</span>} icon={Apfel}/>
              <Info text={<span>Stärken von <span className="font-semibold">Selbstvertrauen</span> und <span className="font-semibold">sozialen Skills</span>.</span>} icon={Sozial}/>
              <Info text={<span>Ein Platz zum <span className="font-semibold">„miteinander Sein“</span>.</span>} icon={Miteinander}/>
              <div className="flex w-full flex-col gap-12 pr-12">
                <div className="text-lg lg:text-3xl font-semibold">Wo und wann findest du uns?</div>
                <div className="text-sm lg:text-lg xl:text-2xl">Termine nach Bedarf und Vereinbarung:<br />
                                                                Donnerstag, 15:00 – 18:00 Uhr<br />
                                                                (Dienstag, 9:00 – 12:00 Uhr bei Bedarf zusätzlich)</div>
              </div>
              <div className="flex w-full flex-col gap-12 pr-12">
                <div className="text-lg lg:text-3xl font-semibold">Anmeldung</div>
                <div className="text-sm lg:text-lg xl:text-2xl">Waltraud Amesbauer-Fabsits<br />
                                                                0664/88 239 639 waltraud.amesbauer-fabsits@sdhf.at<br/>
                                                                Gerda Schöngrundner<br/>
                                                                0664/88 239 647 gerda.schoengrundner@sdhf.at</div>
              </div> 
            </div>
            <div className="text-sm lg:text-lg xl:text-2xl">Treffpunkt: <br />Pfarrhof Hartberg; Eingang von Michaeligasse im Hof</div>
            <div className="h-40"></div>
          </div>
      </div>
    </PageContainer>
  )
}

export default HotPot;