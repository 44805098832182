
const Welcome = () => {
  return(
    <div className="flex flex-col md:flex-row flex-grow md:mb-0">
      <div className=" flex flex-col w-full text-center md:h-full md:text-left md:w-1/2 gap-4 md:gap-8 xl:gap-12 mt-8 pb-4 lg:py-8 xl:py-16">
        <div className="font-semibold text-2xl md:text-2xl xl:text-5xl xl:leading-relaxed">Willkommen bei der <span className="bg-yellow rounded-xl px-2 py-0">ARGE</span> Hartberg-Fürstenfeld!</div>
        <div className="hidden md:flex font-normal md:text-lg w-full md:w-full xl:text-2xl">Die ARGE der Flexible Hilfen Hartberg-Fürstenfeld begleitet Kinder, Jugendliche und Familien bei <br className="hidden xl:flex"/>der Umsetzung ihrer individuellen Ziele. </div>
      </div>
      <div className="w-full md:w-1/2 xl:min-h-full">
        <div className=" p-4 md:p-0 md:pl-8 lg:pl-12 xl:pl-24 xl:pr-4 flex relative flex-col justify-center h-full">
          <img className="rounded-2xl md:w-5/6 lg:w-4/5 xl:w-full object-cover big_image_style" src="./welcome.png"/>
        </div>
      </div>
      <div className="py-6 font-normal text-center md:hidden md:text-lg w-full md:w-4/5 lg:text-justify xl:text-2xl">Die ARGE der Flexible Hilfen Hartberg-Fürstenfeld begleitet Kinder, Jugendliche und Familien bei der Umsetzung ihrer individuellen Ziele. </div>

    </div>
  )
}

export default Welcome