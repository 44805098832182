import money from '../assets/Group 12.svg'
import Geldtasche from '../assets/web/geldtasche_web.svg'
import Apfel from '../assets/web/person_web.svg'

const Footer = () => {
  return(
    <div className="flex flex-col md:flex-row w-full pb-10 gap-8 md:gap-0 py-8 md:py-0 px-4 md:px-0 text-center md:text-start xl:pb-4">
      <div className="w-full md:w-1/2 flex flex-col items-center md:items-start gap-1">
        <img className='h-16 xl:h-20 relative md:right-4' src={Geldtasche} />
        {/*<div className="bg-green rounded-xl image_style relative w-28 h-14 md:w-10 md:h-5 lg:w-16 lg:h-8 xl:w-24 xl:h-12">
        </div>*/}
        <div className="text-sm lg:text-lg font-normal xl:text-2xl">
        Wir bieten <span className="font-semibold">kostenfreie</span> und <span className="font-semibold">öffentlich zugängliche</span> Gruppenaktivitäten.
        </div>
      </div>
      <div className="w-full md:w-1/2 flex flex-col gap-1 md:pl-20 items-center md:items-start">
        <img className='h-16 xl:h-20 relative md:right-4' src={Apfel} />
        <div className="text-sm lg:text-lg font-normal xl:text-2xl">Unsere Projekte entstehen aus <span className="font-semibold">persönlichem Engagement</span> und viel <span className="font-semibold">Herz</span> unserer KollegInnen!</div>
      </div>
    </div>
  )
}

export default Footer;