import Navbar from "../components/Navbar";

const PageContainer = ({children}) => {
  return(
    <div className=" flex-col w-full p-0 flex items-center">
      <div className="pt-8 w-full md:w-5/6 px-8 m-0 flex flex-col justify-between lg:pt-14 xl:pt-24 gap-4 lg:gap-12 xl:gap-0">
        <Navbar />
        <div className="px-4">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageContainer;