import Navbar from "../components/Navbar";
import PageContainer from "./PageContainer";
const images = require.context('../assets/dienste', false, /\.png$/);

const AboutUs = () => {
  const imagePaths = images.keys();

  return(
    <PageContainer>
      <div className="w-full flex flex-col gap-8 lg:gap-12 xl:gap-20 mt-8 lg:mt-20 xl:mt-32">
        <div className="text-lg lg:text-3xl xl:text-5xl font-semibold">Über uns</div>
        <div className="text-sm lg:text-lg xl:text-2xl w-full lg:w-3/4">Die Arbeitsgemeinschaft der Flexiblen Hilfen im Bezirk Hartberg-Fürstenfeld besteht aus 8 privatenEinrichtungen.Die ARGE ist seit 2018 im Rahmen der Flexiblen Hilfe im Sinne des Steiermärkischen Kinder- und Jugendhilfegesetzes nach dem Fachkonzept Casemanagement tätig. Gemeinsam mit derzuständigen Bezirksverwaltungsbehörde (BVB) sind wir bemüht passgenau, flexibel sowie ressourcenorientiert auf die Bedarfe der Familien in der Region einzugehen.<br className="hidden lg:flex"/>Zudem bieten wir, neben der individuellen Begleitung, auch präventive Angebote für Menschen in ihrem Wohnumfeld. In unserem systemisch kooperativen Ansatz ist es uns ein Anliegen regionale Bedarfe wahrzunehmen und mit konkreten Aktivitäten für Eltern, Kinder und Jugendliche, zu reagieren.</div>
        <div className="h-12 xl:h-20 w-full lg:flex grid grid-cols-2 justify-between">
          {imagePaths.map((imagePath, index) => {
            const img = images(imagePath); // Import the image dynamically
            return <img className="object-contain p-4 lg:p-0" key={index} src={img} alt={`image-${index + 1}`} />;
          })}
        </div>
      </div>
    </PageContainer>
  )
}

export default AboutUs;