import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {motion} from "framer-motion"
import { useState } from "react";

const Navbar = () => {

  const [isVisible, setIsVisible] = useState(false)

  const switchIsVisible = () => {
    setIsVisible(!isVisible)
  }

  return(
    <>
      <div className="md:flex w-full items-center gap-16 h-16 hidden">
          <div className="text-md md:text-lg lg:text-2xl xl:text-4xl font-semibold flex-grow py-3"> <Link to={"/"}>ARGE</Link></div>
        
        <Link to='/about_us' className="hidden md:flex">
          <div className="text-sm md:text-lg lg:text-xl xl:text-2xl">Über uns</div>
        </Link>
        <HashLink smooth to={'/#projects'} className="hidden md:flex">
          <div className="cursor-pointer text-sm md:text-lg lg:text-xl xl:text-2xl bg-yellow text-center flex items-center rounded-xl border-2 border-black py-1 px-3 xl:py-3 xl:px-5">Unsere Projekte</div>
        </HashLink>        
      </div>

        <div className="font-semibold text-xl flex flex-col md:hidden w-full h-16 items-center sticky top-0 py-2 z-10 bg-white">
          <div className="w-full flex h-full items-center">
            <div className="text-md md:text-lg lg:text-2xl xl:text-4xl font-semibold flex-grow py-3"> <Link to={"/"}>ARGE</Link></div>
            <motion.div onClick={() => switchIsVisible()} initial={{rotate:270}} animate={{rotate: isVisible ? 90 : 270}}>{'<'}</motion.div>
          </div>
          <motion.div className="w-full text-right bg-white py-4" initial={{scaleY: 0, visibility: 0}} animate={{ scaleY: isVisible ? 1 : 0, visibility: isVisible ? 1 : 0 }}>
            <Link to='/about_us'>
              <div className="text-lg font-medium w-full h-12 flex items-center text-right">Über uns</div>
            </Link>
            <HashLink smooth to={'/#projects'}>
              <div className="text-lg font-medium w-full h-12 flex items-center text-right">Unsere Projekte</div>
            </HashLink>        
          </motion.div>
        </div>
    </>
  )
}

export default Navbar;